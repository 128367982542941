import { dateObjectToStandardAooFormat } from "@/helpers/dateTimeConverter";
import { Order } from "../order/Order";
import { POSOrder } from "../pos/POSOrder";

export class TRD
{
    #id
    #order_type
    #order_id
    #trdNumber
    #created_at
    #order
    #companyInfo    

    customer_taxid
    customer_name
    customer_address
    customer_tel    

    get id() {
        return this.#id
    }

    get order() {
        return this.#order
    }

    get orderNumner() {
        if (!this.#order) return '-'

        return this.#order.orderNumber
    }

    get abbNumber() {
        if (!this.#order) return '-'

        return this.#order.abbNumber
    }

    get order_id() {
        return this.#order_id
    }

    get order_type() {
        return this.#order_type
    }

    get orderType() {
        return this.#order_type == 'saleorder' ? 'ออนไลน์' : 'POS'
    }

    get trdNumber() {
        return this.#trdNumber
    }

    get createdAt() {
        if (!this.#created_at) return '-'

        return dateObjectToStandardAooFormat(new Date(this.#created_at))
    }    

    get branch() {
        if (!this.#order) return 'สำนักงานใหญ่'
        
        return this.#order.branch        
    }
    
    get customerAddress() {
        return this.customer_address
    }

    get customerTaxID() {
        return this.customer_taxid
    }

    get customerTel() {
        return this.customer_tel
    }

    get customerName() {
        return this.customer_name
    }

    get headerTaxID() {
        return this.companyTaxID

        // TODO : remove below ?
        if (this.order_type == 'saleorder') 
            return this.companyTaxID

        return this.#order.headerTaxID
    }

    get seller() {
        if (!this.#order) return '-'

        return this.#order.seller
    }

    get headerAddress() {
        if (this.order_type == 'saleorder') 
            return this.#companyInfo.address + ' ' + this.#companyInfo.city + ' ' + this.#companyInfo.county + ' ' + this.#companyInfo.province + ' ' + this.#companyInfo.postcode

        return this.#order.headerAddress
    }

    get companyName() {
        return this.#companyInfo.name
    }
    

    get companyTel() {
        return this.#companyInfo.tel
    }

    get companyProfile() {
        return this.#companyInfo.profile
    }

    get companyTaxID() {
        return this.#companyInfo.taxid
    }

    get status() {
        if (!this.#order) return 'cancelled'

        return this.#order.status
    }

    constructor({
        id,
        order,
        order_type,
        order_id,
        trdNumber,
        customer_taxid,
        customer_name,
        customer_address,
        customer_tel,
        created_at,                
    }, companyInfo) {
        this.#id = id
        this.#order_type = order_type
        this.#order_id = order_id
        this.#trdNumber = trdNumber
        this.#created_at = created_at

        if (order)
            this.#order = order_type == 'saleorder' ? new Order(order) : new POSOrder(order)

        this.#companyInfo = companyInfo

        this.customer_taxid = customer_taxid
        this.customer_name = customer_name
        this.customer_address = customer_address
        this.customer_tel = customer_tel
    }
}