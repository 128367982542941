<template>
    <div class="d-flex flex-row flex-wrap">
      <div
        v-for="(group, group_index) in labelGroups"
        :key="'group_' + group_index"
        class="d-md-flex btn-card-group d-none"
      >
        <v-card
          v-for="(label, label_index) in group"
          :key="'label_' + label_index"
          :class="['btn-card', 'd-flex', 'justify-center', 'align-center', 'flex-column', { 'active': $attrs.value == label.id }]"
          :style="{
          'backgroundColor': label.color,
          'color': ($attrs.value == label.id ? label.color : label.textColor) + ' !important',
          'border': $attrs.value == label.id ? '2px solid ' + label.color : 'none' }"
          @click="$emit('input', label.id)"
        >
          <div>{{label.text}}</div>
          <div class="text-caption">{{label.subtext}}</div>
          <div>{{label.counter}}</div>
        </v-card>
      </div>
      <v-select
      :items="labelGroupToSelectItems"
      v-model="$attrs.value"
      item-value="id"
      item-text="text"
      v-bind="$attrs"
      @input="$emit('input', $event)"
      outlined
      label="สถานะบิล"
      class="d-md-none d-block"
      :hide-details="true"
      ></v-select>
    </div>
</template>
<script>
export default {
  props: {
    labelGroups: {
      type: Array,
      require: true,
    },
  },
  computed: {
    labelGroupToSelectItems() {
      return this.labelGroups.reduce(
        (previousArray, currentArray) =>
          previousArray.concat(
            currentArray.map(data => {
              let newText = !!data.subtext ? data.text + ' - ' + data.subtext : data.text

              newText += ' (' + data.counter + ') '

              return {
                ...data,
                text: newText,
              }
            }),
          ),
        [],
      )
    },
  },
}
</script>
<style scoped>
.btn-card-group + .btn-card-group {
  margin-left: 10px;
}

.btn-card {
  min-height: 75px;
  min-width: 100px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.btn-card.active {
  background-color: unset !important;
}

.btn-card:hover {
  cursor: pointer;
}

.btn-card + .btn-card:not(:last-child) {
  border-radius: 0;
}

.btn-card:first-child:not(:only-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-card:last-child:not(:only-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>