var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("ใบกำกับภาษีแบบเต็ม")]),_c('v-card',{staticClass:"mt-4 px-6 pt-6"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","placeholder":"ค้นหา ...","dense":""},model:{value:(_vm.datatable.search),callback:function ($$v) {_vm.$set(_vm.datatable, "search", $$v)},expression:"datatable.search"}})],1)],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex pa-4"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.datatable.selectedItems.length == 0,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPrinter))])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.printMultiple(_vm.$PRINT_TYPE.TRD)}}},[_c('v-list-item-title',[_vm._v("ใบกำกับภาษีแบบเต็ม")])],1)],1)],1),_c('v-btn',{staticClass:"ml-0 ml-sm-2",on:{"click":function($event){return _vm.$router.push({ 'name': 'trd_export' })}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.mdiExportVariant))]),_vm._v("ส่งออก")],1),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ 'name': 'trd_create', params: { trd_id: 'create' } })}}},[_vm._v("สร้างใบกำกับภาษีแบบเต็ม")])],1),_c('delay-datatable',{ref:"mainDatatable",attrs:{"show-select":"","headers":_vm.datatable.headers,"url":'/document/trd/list',"queryParams":_vm.getDatatableQueryParams,"options":_vm.datatable.options,"isSaveState":true},on:{"update:options":function($event){return _vm.$set(_vm.datatable, "options", $event)},"loadState":_vm.loadDatatableState},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({attrs:{"value":props.value || props.indeterminate,"indeterminate":props.indeterminate,"color":"primary","ripple":false}},on))]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("timestampToText")(item.created_at))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.customer_name)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.customer_taxid)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.customer_address)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.customer_tel)+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.order.value))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiMenu))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.printSingle(_vm.$PRINT_TYPE.TRD, item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPrinter))])],1),_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("ใบกำกับภาษีแบบเต็ม")])],1),(item.status != 'draft')?_c('v-divider'):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({name: 'trd_create', params: { trd_id: item.id }})}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$store.getters['auth/GET_ABILITY'].can('update', 'Document') ? _vm.mdiPlaylistEdit : _vm.mdiEyeOutline)+" ")])],1),_c('v-list-item-title',{staticClass:"ml-2"},[_vm._v("ดู / แก้ไข")])],1)],1)],1)]}}],null,true),model:{value:(_vm.datatable.selectedItems),callback:function ($$v) {_vm.$set(_vm.datatable, "selectedItems", $$v)},expression:"datatable.selectedItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }